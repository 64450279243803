import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Badge, Flex } from "antd";
import {
  Table,
  Input,
  Button,
  Space,
  Row,
  Col,
  Switch,
  Image,
  Popconfirm,
} from "antd";
import { EditOutlined, EyeOutlined, DeleteOutlined, SortAscendingOutlined } from "@ant-design/icons";
import { getApi, deleteApi } from "../services/api.jsx";
import Swal from "sweetalert2";

const BadgeList = () => {
  const navigate = useNavigate();
  const [searchedText, setSearchedText] = useState("");
  const [badgeList, setBadgeList] = useState([]);
  const [loading, setLoading] = useState(false);
  const formatCreatedDate = (createdDate) => {
    return createdDate ? new Date(createdDate).toLocaleDateString() : "";
  };
  const columns = [
    {
      title: "Status",
      width: 130,
      dataIndex: "isActive",
      key: "isActive",

      filteredValue: [searchedText],
      render: (text, record) => (
        <Badge
          status={record.isActive ? "success" : "error"}
          text={record.isActive ? "Active" : "Inactive"}
        />
      ),
      sorter: (a, b) => b.isActive - a.isActive,
      sortDirections: ["ascend"],
      onFilter: (value, record) => {
        return (
          String(record.id).toLowerCase().includes(value.toLowerCase()) ||
          String(record.isActive).toLowerCase().includes(value.toLowerCase()) ||
          String(record.name).toLowerCase().includes(value.toLowerCase()) ||
          String(record.createdBy)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.createdDate)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.publishedDate)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.modifiedDate)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.createdBy)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.type)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.status).toLowerCase().includes(value.toLowerCase())
        );
      },
    },
    {
      title: "Image",
      dataIndex: "imageURL",
      key: "imageURL",
      width: 100,
      render: (text, record) => (
        <Image
          src={record.imageURL}
          alt="Badge"
          style={{
            width: "50px",
          }}
        />
      ),
    },
    {
      title: () => (
        <div>
          Name
          <SortAscendingOutlined />
        </div>
      ),
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ['ascend'],

    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      sorter: (a, b) => a.type.localeCompare(b.type),
      sortDirections: ['ascend'],

    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
      width: 200,
      onFilter: (value, record) => record.createdBy.indexOf(value) === 0,

    },
    {
      title: "Created Date",
      dataIndex: "createdDate",
      key: "createdDate",
      width: 100,
      render: (text, record) => (
        <span>{formatCreatedDate(record.createdDate)}</span>
      ),
    },
    {
      title: "Last Updated Date",
      dataIndex: "lastUpdatedDate",
      key: "lastUpdatedDate",
      width: 100,
      render: (text, record) => (
        <span>{formatCreatedDate(record.lastUpdatedDate)}</span>
      ),
    },
    {
      title: "Last Updated By",
      dataIndex: "lastUpdatedBy",
      key: "lastUpdatedBy",
      width: 200,
      render: (text, record) => (
        <span>{record.lastUpdatedBy}</span>
      ),
    },
    {
      title: "Expire Date",
      dataIndex: "expireDate",
      key: "expireDate",
      width: 100,
      render: (text, record) => (
        <span>{formatCreatedDate(record.expireDate)}</span>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => {
        return (
          <Space size="middle">
            <Button
              type="dashed"
              icon={<EyeOutlined />}
              onClick={() => badgePreview(record.id)}
            >

            </Button>
            <Button
              type="dashed"
              icon={<EditOutlined />}
              onClick={() => badgeEdit(record.id)}
            >

            </Button>
            <Popconfirm
              title="Are you sure to delete this badge?"
              okText="Yes"
              cancelText="No"
              zIndex={9999}
              onConfirm={() => deleteBadge(record.id)}
            >
              <Button type="dashed" danger icon={<DeleteOutlined />} />
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const getBadgeList = () => {
    setLoading(true);
    console.log("loading:", loading);
    try {
      getApi("/badge/list").then((badgeList) => {
        setBadgeList(badgeList);
        console.log("badgeList:", badgeList);
        setLoading(false);
      });
    } catch (error) {
      console.error("Error while fetching category list:", error);
      throw error;
    }
  };
  const badgePreview = (id) => {
    navigate(`/badge-preview/${id}`);
  };
  const badgeEdit = (id) => {
    navigate(`/badge-edit/${id}`);
  };
  const deleteBadge = (id) => {
    setLoading(true);
    const params = { id: id };
    deleteApi(`/badge/${id}`, params)
      .then(() => {
        getBadgeList();
        console.log("Badge deleted successfully");
        Swal.fire({
          title: "Success!",
          text: "Badge deleted successfully",
          icon: "success",
        });
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error deleting badge:", error);
        let errorMessage = "An error occurred while deleting the badge";
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          errorMessage = error.response.data.message;
        }
        Swal.fire({
          title: "Error!",
          text: errorMessage,
          icon: "error",
        });
        setLoading(false);
      });
  };

  useEffect(() => {
    getBadgeList();
  }, []);

  return (
    <div>
      {loading && (
        <div className="spinner-overlay">
          <div className="spinner" />
        </div>
      )}
      <Row>
        <Col span={6}>
          <Input.Search
            placeholder="Search.."
            onSearch={(value) => {
              setSearchedText(value);
            }}
            onChange={(e) => {
              setSearchedText(e.target.value);
            }}
            style={{ marginBottom: 8 }}
          />
        </Col>
      </Row>

      <Table showSorterTooltip={false}
        className="table-striped-rows"
        bordered={true}
        columns={columns}
        dataSource={badgeList}
      ></Table>
    </div>
  );
};

export default BadgeList;
